import React, { FunctionComponent } from 'react';

import { CopyrightContainer, SourceTextWrapper, LinkContainer } from './style';
import { Props } from './type';

export const Copyright: FunctionComponent<Props> = ({
    author,
    email,
    source,
    sourceText,
    title,
}) => {
    if (!author && !source && !title) {
        return null;
    }

    return (
        <CopyrightContainer>
            {!!source && (
                <span>
                    {author ? ' on ' : ''}
                    <SourceTextWrapper>
                        <LinkContainer
                            href={source}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {sourceText || source}
                        </LinkContainer>
                    </SourceTextWrapper>
                </span>
            )}
            {!!title && <span>{title}</span>}
            {!!email && (
                <span>
                    <a
                        href={`mailto:${email}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {email}
                    </a>
                </span>
            )}
            {/* {!!author && <span>Photo av {author}</span>} */}
        </CopyrightContainer>
    );
};
